@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Mulish:ital,wght@0,400;0,800;1,200&display=swap');

:root {
  --yellow: #F4D846;
  --lightYellow: #f8e27f;
  --lightGrey: #E6E6E6;
  --white: #fffee6;
  --black: #212121;
  --pink: #EEC2E0;
  --lightPink: #fddff0;
  --darkPink: #F69BC9FF;
  --bluegreen: #BEE5CE;
  --red: #E65339;
  --orange: #E79E3D;
  --lightBlack: #545454;
  --border: 0.3em solid var(--black);
  --dropshadow:  0.6em 0.6em 0 var(--black);
  --margin: 2em;
  --minusTop: -9rem;
}

#root {
  display: flex;
  justify-content: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Mulish', sans-serif;
  font-size: 1em;
  background: var(--bluegreen) url('assets/bg/24pxGrid.png') repeat fixed center;
  color: #111111;
}

.page-title h1 {
  font-size: 1.8em;
  margin: 0.1em;
}

h1, h2, h3, h4, h5 {
  font-family: 'Fredoka One', cursive;
  letter-spacing: 0.02em;
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.7rem;
}
h3 {
  margin-bottom: 0.3rem;
}

p {
  margin-bottom: 1rem;
  font-size: 1em;
}

body a {
  color: var(--black);
}

body a:hover {
  box-shadow: inset 0 -.5em 0 0 var(--bluegreen);
}

strong {
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
}

/*-----------PAGE-----------*/

.container {
  background-color: var(--white);
  width: 80%;
  max-width: 100ch;
  min-height: 120ch;
  margin: var(--margin);
  box-shadow: var(--dropshadow);
  border: var(--border);
}

.page-content {
  margin: 3ch;
  top: var(--minusTop);
  position: relative;
}

.highlighter {
  box-shadow: inset 0 -.5em 0 0 var(--yellow);
}

.highlighter:hover {
  box-shadow: inset 0 -.5em 0 0 #F69BC9FF;
  transition: all 0.6s;
  color: black;
}

figcaption {
  margin-top: 0.5rem;
  font-size: 0.8rem;
}

figcaption::before {
  content: "↑ ";
}

.content-bigbox {
  background-color: var(--yellow);
  box-shadow: var(--dropshadow);
  border: var(--border);
  padding: 3em;
  margin-top: var(--margin);
  width: 110%;
  position: relative;
  left: -5%;
  top: var(--minusTop);
}

@media only screen and (max-width: 600px) {
  :root {
    font-size: 14px;
  }

  .page-title h1 {
    font-size: 1.4em;
    margin: 0.1em;
  }

  .header-img-container .page-title {
    padding: 0.6em 0.8em;
  }

  .bookmark-container .bookmark-content ul {
    padding: 1em 0em 1em 1.2em;
  }

  .container .bookmark-container {
    padding: 1em 1.8em 3em 1.9em;
  }

  .container .bookmark-container h3,
  .container .bookmark-container h2 {
    padding: 0em 1em 0.5em 0.2em;
  }

  .nav-container .nav-list, .container .bookmark-container, .container .content-bigbox {
    width: 120%;
    left: -10%;
  }

  .container .bookmark-container {
    width: 108%;
    left: -10%;
  }


}

  @media only screen and (max-width: 390px) {
    :root {
      font-size: 13px;
    }

    .nav-container .nav-list {
      font-size: 0.8rem;
    }

    .page-title h1 {
      font-size: 1.4em;
      margin: 0.1em;
    }

    .btn-text {
      font-size: 0.8em;
    }

    header figure .logo {
      width: 7rem;
    }

   .container header button {
      padding: 0.5em 1.5em;
      margin: 1em;
    }
  }
/*-----------elementen-----------*/

button, header button:visited {
  background-color: var(--orange);
  padding: 0.8em 1.8em;
  margin: var(--margin);
  box-shadow: var(--dropshadow);
  border: var(--border);
  font-size: 1rem;
  border-radius: 50px;
  color: var(--black);
}

button:hover {
  cursor: pointer;
  transform: translateY(0.1em);
  box-shadow: 0.5em 0.5em 0 var(--black);
}

button:active {
  transform: translateY(0.4em);
  color: var(--black);
  box-shadow: 0.2em 0.2em 0 var(--black);
}

.space-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

/*donate button*/
.button-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.donate-btn-txt {
  font-size: 1.2em;
  padding-bottom: 1em;
  font-weight: bolder;
}

.button  a:hover {
  box-shadow: none;
}

.button {
  background-color: var(--orange);
  padding: 0.8em 1.8em;
  margin: var(--margin);
  box-shadow: var(--dropshadow);
  border: var(--border);
  font-size: 1rem;
  border-radius: 50px;
  color: var(--black);
  list-style: none;
  text-align: center;
  width: 20ch;
}

.button:hover {
  cursor: pointer;
  transform: translateY(0.1em);
  box-shadow: 0.5em 0.5em 0 var(--black);
}

.button:active {
  transform: translateY(0.4em);
  color: var(--black);
  box-shadow: 0.2em 0.2em 0 var(--black);
}

.btn-text::after {
  margin-left: 0.2em;
  content: "→";
}

.bigFont {
  font-size: 8rem;
}

.image-preview {
  width: 100px;
}