.dt_Table {
    background-color: var(--lightBlack);
}

.discsTabel .rdt_TableBody .rdt_TableRow {
    background-color: var(--lightBlack);
    border-bottom: 0.3em solid var(--black);
}

.discsTabel .rdt_TableBody .rdt_TableHeadRow {
    background-color: var(--yellow);
    /*border: 0.3em solid var(--black);*/
}

.discsTabel .rdt_TableCol {
    background-color: var(--pink);
    font-weight: bold;
    text-align: center;
    border-bottom: 0.3em solid var(--black);
}

.discsTabel .rdt_TableCell {
    background-color: var(--lightGrey);
    margin: 0;
}

.discsTabel {
    width: 108%;
    left: -4%;
    position: relative;
    border: var(--border);
}

label {
    margin-left: 0ch;
    line-height: 1.5rem;
}

.tabel label:hover {
    font-weight: bold;
}

.tabel-no-data {
    margin: 1em;
}

.legend .questionmark {
    background-color: var(--black);
    color: var(--yellow);
    margin: 0;
    width: 2em;
    height: 2em;
    padding: 0.5em 0.8em;
    border-radius: 5em;
    font-size: .5em;
    font-family: 'Fredoka One', cursive;
    left: 2ch;
    position: relative;
    bottom: 1ch;

}

.legend a.questionmark, .legend a.questionmark:link, .legend a.questionmark:active, .legend a.questionmark:visited {
    text-decoration-line: none;
}

.container .legend a:hover {
    background-color: var(--lightYellow);
    color: var(--black);
    box-shadow: none;
    text-decoration: none;
    cursor: help;

}

.small-txt {
    font-size: 0.8em;
    color: var(--lightBlack);
}

@media only screen and (max-width: 700px) {
    .small-txt::before {
        content: '\A';
        white-space: pre;
    }
    .small-txt {
        margin-left: 1ch;
    }
}

.tabel input {
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid var(--black);
    transition: 0.2s all linear;
    margin-right: 5px;
    position: relative;
    top: 4px;
}

.tabel input:checked {
    border: 6px solid var(--black);
    background-color: var(--pink);
}

.legend, legend {
    color: var(--black);
    padding: 0.3em 0em;
    border-radius: 0;
    border: 0;
    margin-bottom: .1em;
    margin-top: 1.5em;
    font-size: 1.2em;
}

.reset-btn {
    background-color: var(--red);
}