header {
    position: relative;
    height: 8.5em;
    background-color: var(--yellow);
    display: flex;
    justify-content: space-between;
}

header button, header button:visited {
    background-color: var(--bluegreen);
    padding: 0.8em 1.8em;
    margin: var(--margin);
    box-shadow: var(--dropshadow);
    border: var(--border);
    font-size: 1em;
    column-count: var(--black);
    border-radius: 50px;
}

header button:hover {
    cursor: pointer;
}

header button:active {
    transform: translateY(0.4em);
    color: var(--black);
    box-shadow: 0.2em 0.2em 0 var(--black);
}

header button:hover .btn-text {
    display: flex;
}

header .btn-text::after {
    margin-left: 0.2em;
    content: "→";
    transform: translateX(0.3em);
}

header .logo {
    width: 10em;
    padding: 1em;
    transform: rotate(-1.3deg);
    -webkit-backface-visibility: hidden;
    /*padding: 1px;*/
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    -webkit-background-clip:content-box;
    background-clip:content-box;
}

/*-----------HEADER-IMG----------*/


.header-img-container {
    top: -1em;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-img-container figure {
    width: 100%;
    padding-top: 56.25%;
}

.header-img {
    background-color: var(--yellow);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

}

.page-title {
    background-color: var(--bluegreen);
    padding: 1em 3em;
    margin: var(--margin);
    box-shadow: var(--dropshadow);
    border: var(--border);
    top: -6rem;
    position: relative;
    max-width: 70ch;
    text-align: center;
}

/*-----------HEADER-----nav--*/

.nav-container {
    background-color: var(--yellow);

}

.nav-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--pink);
    list-style: none;
    padding: 1em;
    width: 110%;
    position: relative;
    left: -5%;
    top: -0.5rem;
    box-shadow: var(--dropshadow);
    border: var(--border);
    z-index: 1;
    font-size: 1.2rem;
    animation-duration: .8s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: both;
}

.nav-home {
    animation-name: animateLeftSideDown;
    transform: rotate(-1.2deg);
    /*-webkit-backface-visibility: hidden;*/
}

@keyframes animateLeftSideDown {
    50% {
        transform: rotate(-2.2deg);
    }
}

.nav-info {
    animation-name: animateLeftSideDownLight;
    transform: rotate(-1.2deg);
}

@keyframes animateLeftSideDownLight {
    50% {
        transform: rotate(-1.8deg);
    }
}

.nav-vergelijk {
    animation-name: animateRightSideDownLight;
    transform: rotate(1.2deg);
}

@keyframes animateRightSideDownLight {
    50% {
        transform: rotate(1.8deg);
    }
}

.nav-contact {
    animation-name: animateRightSideDown;
    transform: rotate(1.2deg);
}

@keyframes animateRightSideDown {
    50% {
        transform: rotate(2.2deg);
    }
}

.nav-list li a {
    color: var(--black);
    font-weight: bolder;
    text-decoration: none;
}


.nav-list a {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 1em;
    margin: -1em;
}

.nav-list li:active {
    transform: translateY(2px);
    color: var(--black);
}

.nav-list .menu-items :hover {
    box-shadow: inset 0 -.5em 0 0 var(--lightPink);
    transition: all 0.6s;
    color: var(--black);
}

.active-link {
    box-shadow: inset 0 -.5em 0 0 var(--darkPink);
    color: var(--black);
}

.menu-icon {
    font-size: 0.6em;
    margin: 0.2em;
}
