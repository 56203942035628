.page-two-section {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    gap: 5%;
}

.page-two-section img {
    max-width: calc(100% - var(--margin));
    max-height: calc(100% - var(--margin));
    box-shadow: var(--dropshadow);
    border: var(--border);
    border-radius: 0.1em;
    background-color: var(--black);
    outline: 1px solid transparent;


}

.one {
    flex-basis: 100%;
}

.two {
    flex-basis: 45%;
}

.three {
    flex-basis: 30%;
}

.four {
    flex-basis: 21%;
}

.bookmark-container .caption {
    font-size: 0.8em;
}

.caption {
    font-size: 1rem;
    margin-bottom: 2em;

}

.gallery figure {
    position: relative;
    text-align: center;
    /*margin-bottom: 1em;*/
}

.gallery {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.faq-row .gallery {
    margin-left: 1em;
}



.number {
    position: absolute;
    top: -1rem;
    left: -.5rem;
    background-color: var(--yellow);
    margin: 0;
    width: 2.4em;
    height: 2.4em;
    padding: 0.3em 0em;
    border-radius: 5em;
    border: var(--border);
    font-size: 1.4em;
    font-family: 'Fredoka One', cursive;
    z-index: 5;
}



@media only screen and (max-width: 800px) {
    .page-two-section {
        flex-flow: column wrap;
    }
}



